





























import { Component, Vue } from "vue-property-decorator";
import { DashColors } from "@/views/Dashboards/Dashcolors";
import modelo_horarioModule from "@/store/modules/modelo_horario-module";
import { DashTipoDatoPorcen } from "@/shared/dtos/DashboardsDtos/DashTipoDatoPorcen";
import LineChartGroupByTipo from "@/components/Charts/LineChartGroupByTipo.vue";
import { UtilsNumber } from "@/utils/utils-number";
@Component({
  components: {
    PieChart: () => import("@/components/Charts/PieChart.vue")
  }
})
export default class DashCalculoMacrosDieta extends Vue {
  public get sources() {
    let alldata: DashTipoDatoPorcen[] = [];
    for (let i = 0; i < modelo_horarioModule.comidas_y_horarios.length; i++) {
      const element = modelo_horarioModule.comidas_y_horarios[i];
      let data: DashTipoDatoPorcen = new DashTipoDatoPorcen();
      data.tipo = element.nombre_comida;
      data.total = element.porcen;
      data.porcen = element.porcen;
      alldata.push(data);
    }
    return alldata;
  }
  public get paleta() {
    return DashColors.palettecanadian;
  }

  public get CalculosPorComida() {
    let alldata: any[] = [];
    for (let i = 0; i < modelo_horarioModule.comidas_y_horarios.length; i++) {
      let grafico: DashTipoDatoPorcen[] = [];
      const element = modelo_horarioModule.comidas_y_horarios[i];
      let data_proteinas: DashTipoDatoPorcen = new DashTipoDatoPorcen();
      let data_hidratos: DashTipoDatoPorcen = new DashTipoDatoPorcen();
      let data_grasas: DashTipoDatoPorcen = new DashTipoDatoPorcen();

      data_proteinas.tipo = "Proteinas";
      data_proteinas.total =
        (element.calorias *
          modelo_horarioModule.PorcentajesGlobales.porcen_proteinas) /
        100;
      data_proteinas.porcen = data_proteinas.total;

      data_hidratos.tipo = "Hidratos de carbono";
      data_hidratos.total =
        (element.calorias *
          modelo_horarioModule.PorcentajesGlobales.porcen_glucidos) /
        100;
      data_hidratos.porcen = data_hidratos.total;

      data_grasas.tipo = "Grasas";
      data_grasas.total =
        (element.calorias *
          modelo_horarioModule.PorcentajesGlobales.porcen_grasa) /
        100;
      data_grasas.porcen = data_grasas.total;

      grafico.push(data_proteinas);
      grafico.push(data_hidratos);
      grafico.push(data_grasas);
      alldata.push({ name: element.nombre_comida, datos: grafico });
    }
    return alldata;
  }

  public formatear(data: any) {
    return UtilsNumber.RoudTwoDecimals(data);
  }
}
